<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <template slot="header">
          <b-row>
            <b-col sm="6">
              Debt Sequence
            </b-col>
            <b-col sm="6" class="text-right">
              <b-btn size="sm" variant="primary" @click="autoSequence()" :disabled="currentStrategy.locked"><i class="fa fa-bolt"></i> Auto Sequence</b-btn>
            </b-col>
          </b-row>
        </template>
        <b-card-body>
          <table class="table b-table table-drag-n-drop table-sm">
            <thead>
              <tr>
                <th aria-colindex="1" class=""></th>
                <th aria-colindex="2" class="">Order</th>
                <th aria-colindex="3" class="">Name</th>
                <th aria-colindex="4" class="">Type</th>
                <th aria-colindex="5" class="">Timeframe</th>
                <th aria-colindex="6" class="numeric">Balance</th>
                <th aria-colindex="7" class="numeric">Payment</th>
                <th aria-colindex="8" class="numeric">Rate</th>
                <th aria-colindex="9" class="numeric">Payoff Time</th>
                <th aria-colindex="10" class="text-right">Link Status</th>
              </tr>
            </thead>
            <draggable tag="tbody" v-model="debts" @update="saveSort()">
              <tr v-for="debt in debts" :key="debt.id" class="">
                <td aria-colindex="1"><i class="fa fa-reorder"></i></td>
                <td aria-colindex="2">{{ debt.sort }}</td>
                <td aria-colindex="3" class="">{{ debt.name }}</td>
                <td aria-colindex="4" class="">{{ debt.type }}</td>
                <td aria-colindex="5" class=""><date :value="debt.startAt" :showDay="false" format="MM/YYYY" default="Existing"></date></td>
                <td aria-colindex="6" class="numeric"><dollars :value="debt.balance"></dollars></td>
                <td aria-colindex="7" class="numeric"><dollars :value="debt.payment"></dollars></td>
                <td aria-colindex="8" class="numeric"><percent :value="debt.rate"></percent></td>
                <td aria-colindex="9" class="numeric">
                  <time-left :months="debt.monthsToZero"></time-left>
                </td>
                <td aria-colindex="10" class="text-right">
                  <b-button v-if="shouldRemove(debt)" size="sm" variant="ghost-danger" :disabled="currentStrategy.locked" @click.stop="removeDebt(debt)" v-b-tooltip.hover title="The source debt was removed.  Delete debt from strategy?">
                    <i class="fa fa-remove"></i>
                  </b-button>
                  <b-button v-if="shouldUpdate(debt)" size="sm" variant="ghost-warning" :disabled="currentStrategy.locked" @click.stop="updateDebt(debt)" v-b-tooltip.hover title="The source debt has changed.  Sync changes?">
                    <i class="fa fa-refresh"></i>
                  </b-button>
                  <b-button v-if="!shouldUpdate(debt) && !shouldRemove(debt)" size="sm" :disabled="currentStrategy.locked" variant="ghost-success" readonly v-b-tooltip.hover title="No changes to the source debt">
                    <i class="fa fa-check"></i>
                  </b-button>
                  <b-button size="sm" @click.stop="unlink(debt)" variant="ghost-warning" :disabled="currentStrategy.locked" v-b-tooltip.hover title="Unlink the debt">
                    <i class="fa fa-unlink"></i>
                  </b-button>
                </td>
              </tr>
            </draggable>
            <tfoot>
              <tr>
                <td colspan="10" class="text-right">
                  <b-button size="sm" v-if="syncNeeded" :disabled="currentStrategy.locked" variant="ghost-warning" @click.stop="updateAll()" v-b-tooltip.hover title="One or more of the source debts changed.  Sync changes?">
                    <i class="fa fa-refresh"></i> Refresh All
                  </b-button>
                </td>
              </tr>
            </tfoot>
          </table>
          <p><strong>Note: </strong> These debts will not automatically update if the values are changed on the client screen.  Use the link status options to synchronize the changes.</p>
        </b-card-body>
      </b-card>
      <b-card v-if="currentStrategy.id > 0 && missingDebts.length > 0">
        <template slot="header">
          <b-row>
            <b-col sm="6">
              Debts Missing from Sequence
            </b-col>
            <b-col sm="6" class="text-right">
              <b-btn size="sm" variant="primary" @click="addAllDebts()" :disabled="currentStrategy.locked"><i class="fa fa-plus"></i> Add All to Sequence</b-btn>
            </b-col>
          </b-row>
        </template>
        <b-card-body>
          <table class="table b-table table-drag-n-drop table-sm">
            <thead>
              <tr>
                <th aria-colindex="1" class="">Name</th>
                <th aria-colindex="2" class="">Type</th>
                <th aria-colindex="3" class="">Timeframe</th>
                <th aria-colindex="4" class="numeric">Balance</th>
                <th aria-colindex="5" class="numeric">Payment</th>
                <th aria-colindex="6" class="numeric">Rate</th>
                <th aria-colindex="7"></th>
              </tr>
            </thead>
            <tr v-for="debt in missingDebts" :key="debt.id" class="">
              <td aria-colindex="1" class="">{{ debt.name }}</td>
              <td aria-colindex="2" class="">{{ debt.type }}</td>
              <td aria-colindex="3" class=""><date :value="debt.startAt" :showDay="false" format="MM/YYYY" default="Existing"></date></td>
              <td aria-colindex="4" class="numeric"><span><dollars :value="debt.balance"></dollars></span></td>
              <td aria-colindex="5" class="numeric"><span><dollars :value="debt.payment"></dollars></span></td>
              <td aria-colindex="6" class="numeric"><span><percent :value="debt.rate"></percent></span></td>
              <td aria-colindex="7" class="text-right">
                <b-button size="sm" variant="ghost-success" :disabled="currentStrategy.locked" @click.stop="addDebt(debt)" v-b-tooltip.hover title="Add to Sequence">
                  <i class="fa fa-plus"></i>
                </b-button>
              </td>
            </tr>
          </table>
          <p><strong>Note: </strong> These debts were added after the strategy was created.</p>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Date from '@/components/text/Date'
import Dollars from '@/components/text/Dollars'
import Percent from '@/components/text/Percent'
import TimeLeft from '@/components/text/TimeLeft'
import draggable from 'vuedraggable'

export default {
  name: 'Debts',
  components: {
    Date,
    Dollars,
    draggable,
    Percent,
    TimeLeft
  },
  data: () => {
    return {
      debts: [],
      fields: [
        { key: 'type', label: 'Type' },
        { key: 'balance', label: 'Balance', class: 'numeric' },
        { key: 'payment', class: 'numeric' },
        { key: 'rate', class: 'numeric' },
        { key: 'monthsToZero', label: 'Payoff Time', class: 'numeric'},
        { key: 'actions', label: '', class: 'text-right' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'global/notifications',
      currentClient: 'global/currentClient',
      currentStrategy: 'global/currentStrategy'
    }),
    syncNeeded () {
      let syncNeeded = false
      this.debts.forEach((debt) => {
        if (this.shouldUpdate(debt)) {
          syncNeeded = true
        }
      })
      return syncNeeded
    },
    missingDebts () {
      let missingDebts = []

      // Missing Credit Cards
      this.currentClient.creditCards.forEach((creditCard) => {
        let index = this.debts.findIndex((o) => o.creditCardId === creditCard.id)
        if (index < 0) {
          missingDebts.push({
            strategyId: this.$route.params.strategyId,
            name: creditCard.name,
            type: 'Credit Card',
            creditCardId: creditCard.id,
            balance: creditCard.balance,
            payment: creditCard.minimumPayment,
            rate: creditCard.rate,
            sort: null
          })
        }
      })

      // Missing Loans
      this.currentClient.loans.forEach((loan) => {
        let index = this.debts.findIndex((o) => o.loanId === loan.id)
        if (index < 0) {
          missingDebts.push({
            strategyId: this.$route.params.strategyId,
            name: loan.name,
            type: 'Loan',
            loanId: loan.id,
            balance: loan.balance,
            payment: loan.minimumPayment,
            rate: loan.rate,
            sort: null
          })
        }
      })

      // Missing Mortgages
      this.currentClient.mortgages.forEach((mortgage) => {
        let index = this.debts.findIndex((o) => o.mortgageId === mortgage.id)
        if (index < 0) {
          missingDebts.push({
            strategyId: this.$route.params.strategyId,
            name: mortgage.name,
            type: 'Mortgage',
            mortgageId: mortgage.id,
            balance: mortgage.balance,
            payment: mortgage.minimumPayment,
            rate: mortgage.rate,
            sort: null
          })
        }
      })

      // Missing Vehicles
      this.currentClient.vehicles.forEach((vehicle) => {
        let index = this.debts.findIndex((o) => o.vehicleId === vehicle.id)
        if (index < 0) {
          missingDebts.push({
            strategyId: this.$route.params.strategyId,
            name: vehicle.name,
            type: 'Vehicle',
            vehicleId: vehicle.id,
            balance: vehicle.balance,
            payment: vehicle.minimumPayment,
            rate: vehicle.rate,
            sort: null
          })
        }
      })

      return missingDebts
    }
  },
  methods: {
    unlink (debt) {
      this.$api.strategies.debts.delete({ id: debt.id })
        .then(() => {
          let index = this.debts.findIndex((o) => {
            return o.id === debt.id
          })
          if (index >= 0) {
            this.debts.splice(index, 1)
          }
          this.saveSort()
        })
        .catch(() => {
          this.$snotify.error('Add Debt Failed')
        })
    },
    sourceDebt (debt) {
      let sourceDebt = null
      switch (debt.type) {
        case 'Credit Card':
          if (debt.creditCard) sourceDebt = debt.creditCard
          break
        case 'Loan':
          if (debt.loan) sourceDebt = debt.loan
          break
        case 'Mortgage':
          if (debt.mortgage) sourceDebt = debt.mortgage
          break
        case 'Vehicle':
          if (debt.vehicle) sourceDebt = debt.vehicle
          break
      }
      return sourceDebt
    },
    shouldRemove (debt) {
      if (!this.sourceDebt(debt)) return true
      return false
    },
    shouldUpdate (debt) {
      let sourceDebt = this.sourceDebt(debt)
      if (sourceDebt) {
        if (sourceDebt.name != debt.name) return true
        if (sourceDebt.startAt != debt.startAt) return true
        if (sourceDebt.newMoney != debt.newMoney) return true
        if (sourceDebt.balance != debt.balance) return true
        if (sourceDebt.rate != debt.rate) return true
        if (sourceDebt.minimumPayment != debt.payment) return true
      }
      return false
    },
    addDebt (debt) {
      this.$api.strategies.debts.create(debt)
        .then((response) => {
          this.debts.push(response.data)
          this.saveSort()
        })
        .catch(() => {
          this.$snotify.error('Add Debt Failed')
        })
    },
    addAllDebts () {
      let updates = []
      this.missingDebts.forEach((debt) => {
        updates.push(this.$api.strategies.debts.create(debt).then((response) => this.debts.push(response.data)))
      })
      Promise.all(updates).then(() => {
        this.saveSort()
      }).catch(() => {
        this.$snotify.error('Add Debts Failed')
      })
    },
    updateDebt (debt) {
      let sourceDebt = this.sourceDebt(debt)

      if (sourceDebt) {
        debt.name = sourceDebt.name
        debt.startAt = sourceDebt.startAt
        debt.newMoney = sourceDebt.newMoney
        debt.balance = sourceDebt.balance
        debt.payment = sourceDebt.minimumPayment
        debt.rate = sourceDebt.rate
        this.$api.strategies.debts.update({ id: debt.id }, debt)
          .catch(() => {
            this.$snotify.error('Error updating the debt')
          })
      }
    },
    updateAll () {
      let synching = []
      this.debts.forEach((debt) => {
        if (this.shouldUpdate(debt)) {
          let sourceDebt = this.sourceDebt(debt)

          if (sourceDebt) {
            debt.name = sourceDebt.name
            debt.startAt = sourceDebt.startAt
            debt.newMoney = sourceDebt.newMoney
            debt.balance = sourceDebt.balance
            debt.payment = sourceDebt.minimumPayment
            debt.rate = sourceDebt.rate
            synching.push(this.$api.strategies.debts.update({ id: debt.id }, debt))
          }
        }
      })
      Promise.all(synching)
        .catch(() => {
          this.$snotify.error('Error updating the debts')
        })
    },
    removeDebt (debt) {
      this.$api.strategies.debts.delete({ id: debt.id })
        .then(() => {
          let index = this.debts.findIndex((o) => o.id === debt.id)
          if (index >= 0) this.debts.splice(index, 1)
          this.saveSort()
        })
        .catch(() => {
          this.$snotify.error('Delete debt failed')
        })
    },
    autoSequence () {
      function compare(a, b) {
        if (parseFloat(a.balance) < parseFloat(b.balance))
          return -1;
        if (parseFloat(a.balance) > parseFloat(b.balance))
          return 1;
        return 0;
      }

      this.debts.sort(compare);
      this.saveSort()
    },
    mapDebts (debts) {
      this.debts = []
      debts.forEach((debt) => {
        this.debts.push(debt)
      })
    },
    saveSort () {
      this.$store.dispatch('global/saveStrategy', {
        id: this.currentStrategy.id,
        debts: this.debts
      })
    }
  },
  watch: {
    'currentStrategy.debts': {
      handler (debts) {
        this.mapDebts(debts)
      }
    }
  },
  created () {
    this.$store.dispatch('global/getStrategy', {
      id: this.$route.params.strategyId
    })
    if (this.currentClient.id != this.$route.params.id) {
      this.$store.dispatch('global/getClient', {
        id: this.$route.params.id
      })
    }
    if (this.currentStrategy.debts) {
      this.mapDebts(this.currentStrategy.debts)
    }
  },
  validations: {
    debts: {
      $each: {}
    }
  }
}
</script>

<style>
  div.card-body {
    padding: 0.75em 1.25em 0.75em 1.25em;
  }
  table.table-sm td.numeric, table.table-sm th.numeric {
    text-align: right;
  }
  .table-sm td.delete {
    width: 28px;
  }
  .table-sm td {
    vertical-align: middle;
  }
  .table-sm td.numeric, .table-sm th.numeric {
    text-align: right;
  }
</style>
